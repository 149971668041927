<template>
  <v-app>
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-icon x-large color="red">mdi-fire</v-icon>
        <h1>Sauna Status</h1>
      </v-row>

      <v-divider class="ma-4"></v-divider>

      <v-main>
        <router-view />
      </v-main>
    </v-container>
  </v-app>
</template>

<style>
</style>

<script>
export default {
  name: "app"
};
</script>
