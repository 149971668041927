<template>
  <v-container fluid>
    <v-row justify="center" class="ma-6">
      <canvas
        data-type="radial-gauge"
        id="gauge-t"
        data-width="300"
        data-height="300"
        data-animation-duration="800"
        data-units="°C"
      ></canvas>
    </v-row>
    <v-row justify="center" class="mb-6">
      <canvas
        data-type="radial-gauge"
        id="gauge-h"
        data-width="300"
        data-height="300"
        data-animation-duration="800"
        data-units="%"
      ></canvas>
    </v-row>
    <v-row justify="center">最終更新 : {{ timestamp }}</v-row>
  </v-container>
</template>

<script>
import axios from "axios";

const baseURL =
  "https://9jpfa1kulk.execute-api.ap-northeast-1.amazonaws.com/test/get";

export const secureHTTP = axios.create({
  headers: {
    "x-api-key": "GCQ2QDJmhB9KR43PbqZl03bbMpSQwFrZ8siYr2FF"
  }
});

export default {
  name: "Home",

  data: () => ({ timestamp: null }),
  methods: {
    someMethod() {
      var getStatus = function() {
        secureHTTP
          .post(baseURL)
          .then(response => {
            console.log(response.data);
            document
              .getElementById("gauge-t")
              .setAttribute("data-value", response.data.temp);
            document
              .getElementById("gauge-h")
              .setAttribute("data-value", response.data.humidity);
            this.timestamp = response.data.timestamp;
          })
          .catch(error => {
            console.log(error);
          });
      };
      getStatus();
      setInterval(
        function() {
          getStatus();
        }.bind(this),
        10000
      );
    }
  },
  created() {
    this.someMethod();
  }
};
</script>
